.DropdownContainerwidth {
  width: 170px !important;
}
/* .header-fixed.toolbar-fixed .wrapper {
  padding-top: 40px !important;
} */
.max {
  max-height: "90%" !important;
}
.jqabtN {
  z-index: 0 !important;
}
.hIWEKa {
  z-index: 0 !important;
}
.fullscreen-enabled .my-component {
  background: white;
  width: 6000px !important;
}


.header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .wrapper {
  padding-top: 40px !important;
}

.dropdown-toggle{
  background-color: transparent;
}

._small-image {
  height: 50px;
  object-fit: cover;
}





/* 

[data-column-id="ID"].rdt_TableCol {
  font-weight: 500;
  width: 30px;
}
[data-column-class="commoncss"].rdt_TableCol {
  font-weight: 500;
}
[data-column-id="director"].rdt_TableCol {
  background-color: orange;
}


.pac-container{
  z-index: 99999999999 !important;
}  */
.notif-read {
    background-color: #f5f4f3;
    color: #0574b4;
    font-weight: 500;
}

.notif-read,
.notif-unread {
    cursor: pointer;
}

.notif-read:hover,
.notif-unread:hover {
    border-color: black;
    border-width: .2px;
}

.notification-mail-icon {
    font-size: 18px;
}


.drawer-enter {
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
}
.drawer-enter-active {
    transform: translateX(0);
}
.drawer-exit {
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
}
.drawer-exit-active {
    transform: translateX(100%);
}

.drawer-container {

    width: 100vh !important;
  }


  .customCss{
    min-height: 85 !important;
  }

  .margins:last-child {
    margin-bottom: 250px !important;
}


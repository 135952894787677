.modal-large{
    --bs-modal-width: 80%;
}

.customerAllVehiclesRenewalsTable .d-th{
    font-size: 10px !important;
}
.pause_paid, .pause_unpaid, .inactive_auto, .inactive_agent{
    border: 1.5px solid #8e8e8e !important;
}

.refresh-icon {
    font-size: 2rem;
    position: relative;
    bottom: 4px;
    right: 5px;
    cursor: pointer;
}
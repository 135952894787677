.bg-image {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  padding: 5px;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.img-text {
  position: relative;
  top: -60%;
  color: white;
}
.fa-times{
    cursor: pointer;
}
.fa-times:hover{
    color: red;
}


.customer-detail-bodys{
    height: calc(100vh - 50px);
    overflow-x: hidden;
}
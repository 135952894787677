.containerDiv {
  width: 100%;
  /* border: 1px solid red; */
  /* position: absolute; */
  margin: auto;
  left: 0;
  right: 0;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

.containerDivLeft {
  width: 35%;
  display: flex;
  justify-content: start;
  gap: 5px;
}

.boxsRight {
  padding-bottom: 8px;
}
.box1 {
width: 50%;
}

.containerDivRight {
  width: 64%;
  display: flex;
  justify-content: end !important;
  align-items: center;
  gap: 1px;
}




.bordercss{
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.89) !important;
 
}

.outlinecss{
    outline-color: none !important;
    border: none !important;
}

.hovercss:hover{
    font-size: 15px !important;
}

.hover-effect::before {
    content: "";
    position: absolute;
    left: -10px; 
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 100%;
    background-color: transparent;
    transition: background-color 0.3s;
}

.hover-effect.hover::before {
    background-color: #007bff; 
}

.phone_number_mandatory {
  position: absolute !important;
  font-size: 30px;
  color: red;
  top: -40%;
  left: 2%;
  /* background-color: black; z-index: 2; */
  z-index: 2;
}

.phone_number_mandatory2 {
  position: absolute !important;
  font-size: 30px;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  font-weight: 1000;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  /* color: red; */
  top: 18%;
  left: -2px;
  background-color: white;
  padding-top: -30px;
  z-index: -1;
}

.parentscss {
  position: relative;
}

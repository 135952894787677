.popsssss{
width: calc(100% ) !important;
height: calc(100%) !important;
position: absolute !important;
Z-index : 999 !important;
/* background-color: rgb(30,30,45); */

margin: auto !important;
/* top: 30% !important; */
top: 0 !important;
/* left: 0!important; */
left: 17% !important;
}
.semitrans{
    width: 50%;
    height: 50%;

    background-color: aliceblue;
    opacity: 1;
}
.Closes{
    width: 100% ;
    position: relative !important;
    Z-index : 999 !important;
    top: 0 !important;
    /* left: 0!important; */
    left: 17% !important; 
}
/* width: "100vh", height: "auto", position: "absolute", zIndex: 999, background: "red", margin: "auto", top: "30%", left: "30%" */

/* style={{ width: "100vh", height: "auto", position: "absolute", zIndex: 999, background: "red", margin: "auto", top: "30%", left: "30%" }} */
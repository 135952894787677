.scrollable-div {
    width: 100%; 
    height: 70px;
    overflow-x: scroll; 
    overflow-y: hidden; 
    display: flex;
    justify-content:start;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 5px;
    gap: 5px;
}

.scrollable-div::-webkit-scrollbar {
    width: 10px;
    height:10px
}

.scrollable-div::-webkit-scrollbar-track {
    background: #eeeeee; 
}

.scrollable-div::-webkit-scrollbar-thumb {
    background: #eeeeee; 
    border-radius: 5px; 
    height:10px

}

.scrollable-div::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
    height:10px

}

.scrollable-div {
    scrollbar-width: thin; 
    scrollbar-color: #4CAF50 #f1f1f1; 
}
.generatedTable,
.estimatedReportTable,
.generatePayoutReportTab {
    cursor: pointer;
}

.activeTab {
    background-color: rgb(116, 116, 116) !important;
}

.activeTab .body {
    background-color: #ffc107 !important;
}

.generatePayoutReportTab:hover {
    background: #3d3d3d;
}

.generatePayoutReportTab .body:hover {
    background: #61d9f7;
}

.col-sm {
    font-weight: 600;
}

.just-padding {
    padding: 15px;
}

.list-group.list-group-root {
    padding: 0;
    overflow: hidden;
}

.list-group.list-group-root .list-group {
    margin-bottom: 0;
}

.list-group.list-group-root .list-group-item {
    border-radius: 0;
    border-width: 1px 0 0 0;
}

.list-group.list-group-root>.list-group-item:first-child {
    border-top-width: 0;
}

/* ---------1---------- */

.list-group.list-group-root1 {
    padding: 0;
    overflow: hidden;
}

.list-group.list-group-root1 .list-group {
    margin-bottom: 0;
}

.list-group.list-group-root1 .list-group-item {
    border-radius: 0;
    border-width: 1px 0 0 0;
}

.list-group.list-group-root1>.list-group-item:first-child {
    border-top-width: 0;
}

/* ----------2--------- */

.list-group.list-group-root2 {
    padding: 0;
    overflow: hidden;
}

.list-group.list-group-root2 .list-group {
    margin-bottom: 0;
}

.list-group.list-group-root2 .list-group-item {
    border-radius: 0;
    border-width: 1px 0 0 0;
}

.list-group.list-group-root2>.list-group-item:first-child {
    border-top-width: 0;
}

/* ----------3--------- */

.list-group.list-group-root3 {
    padding: 0;
    overflow: hidden;
}

.list-group.list-group-root3 .list-group {
    margin-bottom: 0;
}

.list-group.list-group-root3 .list-group-item {
    border-radius: 0;
    border-width: 1px 0 0 0;
}

.list-group.list-group-root3>.list-group-item:first-child {
    border-top-width: 0;
}

/* .list-group.list-group-root>.list-group>.list-group-item {
        padding-left: 30px;
    }

    .list-group.list-group-root>.list-group>.list-group>.list-group-item {
        padding-left: 45px;
    } */

.list-group-item .fa {
    margin-right: 5px;
}

.pl-85 {
    padding-left: 85px !important;
}

.list-group-item {
    color: white !important;
}

.mr-19r {
    margin-right: 19rem !important;
}

.tillNowFontColor {
    color: white;
    font-weight: 900;
}

.projectedFontColor {
    color: white;
    font-weight: 900;
}

.headerT {
    font-weight: 900;
}

#viewJobSummery .col-sm {
    color: black;
}
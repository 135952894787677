
div.rdt_TableHead {
  background: white !important;
  z-index: 2 !important;
}


div > [data-column-id="150"] {
  position: sticky;
  left: 0; 
  background-color: #fff;
  z-index: 3 !important;
  box-shadow: '3px 0px 3px 0px rgba(0,0,0,0.2)' !important;
  border: 'none' !important;
  border-left: '2px solid rgba(0,0,0,0.2)' !important;
}

.apexcharts-text tspan{

}
/* .apexcharts-xaxis-texts-g > text {
    transform: none !important;
    width: 10vw !important;
} */
#SvgjsTspan2399 {
    /* word-break: break-all !important; */
    /* overflow-wrap: break-word !important; */
    word-wrap: break-word !important;
    /* font-size: 50px !important; */
    width: 20px !important;
    height: 20px !important;
    border: 2px solid black !important;
}
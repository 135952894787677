.containerDiv {
  width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  gap: 10px;
}

.containerDivLeft1 {
  width: 20%;
  display: flex;
  justify-content: start;
  align-items:start;
  gap: 5px;
  padding: 2px;

}

.containerDivLeftContainer{
  width: 35%;
  display: flex;
  justify-content: start;
  gap: 5px;
  padding: 2px;

}

.boxsRight {
  padding-bottom: 2px;
}

.containerDivRight {
  width: 64%;
  display: flex;
  justify-content: end !important;
  align-items: center;
  gap: 1px;
}

/* mobile device >>>>>>>  */
@media only screen and (max-width: 486px) {
  .containerDiv {
    width: 100%;
    flex-direction: column;
  }

  .containerDivLeft1 {
    width: 100%;
    flex-direction: column;
  }
  .containerDivLeftContainer {
    width: 100%;
    flex-direction: column;
  }

  .containerDivRight {
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }
  .box1 {
    width: 100%;
  }
}

/* tablet   */
@media screen and (min-width: 486px) and (max-width: 876px) {
  .containerDiv {
    width: 100%;
    flex-direction: column;
  }
  .containerDivLeft1 {
    width: 100%;
  }
  .containerDivLeftContainer {
    width: 100%;
  }

  .containerDivRight {
    width: 100%;
    gap: 10px;

  }
  .box1 {
    width: 50%;
  }
  .box2 {
    width: 30%;
  }
  .boxsRight {
    padding-bottom: 2px;
  }
}

#upload {
    opacity: 0;
    overflow:"hidden"
}

#upload-label {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    overflow:"hidden"

}
#upload-label:hover {
    z-index: -0.9;
}

.image-container {
    position: relative;
}
.image_gallery{
    position: relative;

}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom:0;
    width: 100%;
    height: 96.5%;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    border-radius:5px;
}

.editActionButton{
    position: absolute;
    padding-bottom: .5px;
    display:flex;
    cursor: pointer;
    bottom: 0;
    right: 0;

}

.edit-button {
    padding: 2px 10px;
    background-color: #fff;
    color: #000;
    border: 3px solid whitesmoke;
    cursor: pointer;

}

.editButton2 {
    position: absolute;
    padding: 3px 5px;
    background-color: #fff;
    color: black;
    border: none;
    cursor: pointer;
    top: 0;
    right: 0;
}

.image-container:hover .overlay {
    display: flex;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.accordion-button {
    padding: 4px !important;
    background-color: #f4f8fa ;
    
}
.accordion-button::after{
    flex-shrink: 0;
    width: 0 !important;
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}
.accordion-button:not(.collapsed) {
    background-color: #f4f8fa !important;
}

.accordion-item {
    border: 1px solid #dcdcdc !important;
    margin: 3px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.customcss {
    height: 100vh !important;
    overflow-y: hidden;
}

.customer-detail-bodys {
    max-height: calc(100vh - 50px);
    overflow-x: hidden;
}

.margins:last-child {
    margin-bottom: 150px !important;
}

tr.active {
    background-color: rgb(222 237 255);
}

.debited_row {
    color: red !important;
}

.fa-eye {
    cursor: pointer !important;
}

.credited_row {
    color: green !important;
}

.chatHistryBgColor{
    background: rgb(135, 199, 196) !important;
}

.selectBox {
  outline: none;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 5px;
  width: 150px;
  height: 30px;
}

.dropDownOverideCss {
  background: #fdfdfd !important;
  /* border: 1px solid cadetblue !important; */
  /* overflow: auto !important; */
  /* min-height: auto; */
  /* max-height: 500px; */
}

.filterBtn {
  border: 1px solid cadetblue !important;
}
.colorBug {
  color: cadetblue !important;
}
/* 
@media screen and (max-width: 1200px) {
  .filterBtn {
    width: 100% !important;
    position: absolute;
  }
} */

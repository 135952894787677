$primary: #1f90c5;
$secondary: #f2fdfd;
$fontsize: 14px;
$fontsans: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica",
  "Arial", sans-serif;
$fontweight: 400;
$letterspacing: 0.4px;
$lineheight: 1.5;

::-webkit-scrollbar{height:8px;width:8px;background:$secondary;}
::-webkit-scrollbar-thumb{background:$primary;}

* {
  box-sizing: border-box;
}

// html,
// body {
//   position: relative;
//   height: 100%;
// }

// body {
//   margin: 0;
//   padding: 0;
//   overflow-x: hidden;
//   font-family: $fontsans;
//   font-size: $fontsize;
//   font-weight: $fontweight;
//   letter-spacing: $letterspacing;
//   line-height: $lineheight;
// }

.calendar {

  width: 100%;

  .header {
    // height: 4rem;
    // line-height: 4rem;
    text-align: center;

    background: darken($primary, 10%);
    color: $secondary;

    .left,
    .center,
    .right {
      width: calc(100% / 3 - 1rem);
      float: left;

      h1 {
        line-height: 1.8rem;
        font-size: 25px;
        text-transform:uppercase;
        color: white;
      }

      button {
        background-color: darken($primary, 15%);
        border: 1px solid darken($primary, 20%);
        color: $secondary;
        padding: 0.5rem 1rem;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 0 4px;
        cursor: pointer;
        transition: all 500ms ease;

        &:hover {
          background-color: darken($primary, 12%);
          border-color: darken($primary, 12%);
          color: $secondary;
          transition: all 500ms ease;
        }
      }
    }
  }

  .days,
  .weekdays {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      display: block;
      float: left;
      width: calc(100% / 7);
      padding: 5px;
      box-sizing: border-box;
    }
  }

  .weekdays {
    height: 40px;
    background: darken($primary, 15%);
    border-bottom: 2px solid darken($primary, 20%);

    li {
      text-align: center;
      text-transform: uppercase;
      line-height: 20px;
      border: none !important;
      padding: 10px 6px;
      color: $secondary;
      font-size: 13px;
      font-weight: bold;
    }

    .today {
      background: darken($primary, 20%);
      color: $secondary;
    }
  }

  .days {
    li {
      height: 113px;
      overflow-y: auto;
      background: $secondary;
      position: relative;
      color: darken($primary, 20%);
      border: 1px solid darken($secondary, 5%);
			cursor:pointer;
      .info {
        position: absolute;
        bottom: 2px;
        right: 2px;
        z-index:1;
        opacity: 0;
      }
      .date {
        text-align: center;
        margin-bottom: 5px;
        background: lighten($primary, 15%);
        color: $secondary;
        width: 25px;
        height: 25px;
        line-height: 25px;
        border-radius: 50%;
        float: right;
        font-size: 12px;
        font-weight: bold;
        z-index:2;
      }
    }
    
    .days-before{background: darken($secondary, 3%)}
    .days-after{background: darken($secondary, 3%)}
    .today {
      background: darken($secondary, 10%);
      .date{
         background: $primary
      }
    }
  }
}

.ev {
  display: block;
  background: lighten($primary, 40%);
  border: 1px solid lighten($primary, 30%);
  border-radius: 4px;
  margin: 5px auto;
  transition: background 500ms ease;
}
.ev:hover {
  // opacity:0.5;
  // transition: opacity 500ms ease;
}
.ev-desc {
  padding: 0.2rem 0.5rem;
  font-size:12px;
}
.label_info{
  background:aqua;
  border:1px solid aqua;
  color:black;
}
.label_warning{
  background:yellow;
  border:1px solid yellow; 
  color:black;
}
.label_primary{
  background:blue;
  border:1px solid darkblue;
  color:white;
}
.label_danger{
  background:rgb(197, 75, 4);
  border:1px solid rgb(197, 75, 4);
  color:white;
}

@media (max-width: 1024px) { 
	.right{display:none;}
.calendar .header .left, 
	.calendar .header .center{
		width:calc(100%/2 - 1rem)
	}
}
@media (max-width: 768px) {

  .calendar {
    
    .header {
      height: auto;
      text-align: center;
      padding: 1rem;

      .left,
      .center,
      .right {
        width: 100%;
        float: none;
      }
    }
    
    .weekdays,
    .null {
      display: none !important;
    }

    .days li {
      height: auto !important;
      border: 1px solid darken($secondary, 5%);
      width: 100%;
      padding: 10px;
      margin-bottom: -1px;
      .info {
        left: 2px;
        opacity: 1;
        color: lighten($primary, 35%);
      }
    }
    .date {
      float: none;
    }
  }
}
.calendar .header{
    left: 0 !important;
    // position: unset !important;
}

.old-jobs-cal .btn-close{
    z-index: 9999 !important;
    background-color: white !important;
}

.calendar .btn-close{
    color: white;
}
.calendar .fa-arrow-left{
    color: white;
}

.calendar .fa-arrow-right{
    color: white;
}

.calendar .fa-calendar{
    color: white;
}

.calendar h4{
  color: white;
}

.calendar p{
  color: white;
  line-height: 3px;
} 
@media screen and (min-width: 861px) {
  .isPosition {
    position: sticky !important;
  }
}

@media screen and (max-width: 861px) {
  .isPosition {
    top: 10px !important;
  }
}

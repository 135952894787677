//
// Root
//

html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}

html,
body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $root-font-size !important;
    font-weight: $font-weight-base;
    font-family: $font-family-sans-serif;

    // Tablet mode
    @include media-breakpoint-down(lg) {
        font-size: $root-font-size-lg !important;
    }

    // Mobile mode
    @include media-breakpoint-down(md) {
        font-size: $root-font-size-md !important;
    }    
}

body {
    display: flex;
    flex-direction: column;

    a:hover,
    a:active,
    a:focus {
        text-decoration: none !important;
    }
}

canvas {
    user-select: none;
}

// Angular integration
router-outlet {
    display: none;
}

.stats-link {
    font-size: 1em;
    color: black;
}

.stat-links .active {
    color: #009ef7 !important;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
}
.formMendatoryFieldCss {
    position: absolute !important;
    font-size: 20px;
    color: #d80506;
    top: 17%;
    left: 0%;
    z-index: 2 !important;
    transform: translateY(-50%); 
  }

  .formMandatoryFieldCssChild {
    position: absolute !important;
    font-size: 16px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    font-weight: bold;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    top: 38%;
    left: -3px; 
    transform: translateY(-50%); 
    background-color: white;
    z-index: -1; 
    font-weight: 1000;
    
  }

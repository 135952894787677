.selectBox {
  outline: none;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 5px;
  width: 150px;
  height: 30px;
}
.dropDownOverideCss {
  background: #fdfdfd !important;
}
.filterBtn {
  border: 1px solid cadetblue !important;
}
.colorBug {
  color: cadetblue !important;
}
.tanslateIt {
  transform: translate(0, 29px) !important;
  inset: 0px auto auto 0px !important;
}

.font-black {
    color: black;
}


.customer-detail-bodys {
    max-height: calc(100vh - 50px);
    overflow-x: hidden;
}

.margins:last-child {
    margin-bottom: 250px !important;
}
.eQobRN{
    margin-bottom: 150px !important;
    min-height: 85% !important;
}
#kt_drawer_chat2_messenger {
    border-bottom-style: none !important;
}

.card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    height: 100px !important;
    padding: 0 2.25rem;
    color: var(--kt-card-cap-color);
    background-color: var(--kt-card-cap-bg);
    border-bottom: 1px solid var(--kt-card-border-color);
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .fs-4 {
        font-size: 10px !important;
    }

    .font-15 {
        font-size: 10px !important;
    }
}

#kt_chat_messenger_body {
    /* overflow-y: scroll; */
}

.chat-box-main {
    /* max-height: 100vh !important; */

}

.chat-hostory-body {
    max-height: calc(100vh - 50px);
    overflow-y: scroll;
}

.customer-detail-body {
    max-height: calc(100vh - 50px);
    overflow-y: scroll;
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .fs-4 {
        font-size: 10px !important;
    }

    .font-15 {
        font-size: 10px !important;
    }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    .fs-4 {
        font-size: 10px !important;
    }

    .font-15 {
        font-size: 10px !important;
    }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    .fs-4 {
        font-size: 10px !important;
    }

    .font-15 {
        font-size: 10px !important;
    }
}

/**********
    iPad 3
    **********/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    .fs-4 {
        font-size: 10px !important;
    }

    .font-15 {
        font-size: 10px !important;
    }
}

.more-options {
    position: absolute;
    right: 0;
    top: 10px;
}



.customer-detail-bodys {
    height: calc(100vh - 50px);
    overflow-x: hidden;
}

.chat-hostory-body-content {
    max-height: calc(100vh - 50px);
    overflow-y: hidden;
}

.split {
    display: flex;
    flex-direction: row;
}

.gutter {
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}

    

.chatsection {
    position: absolute;
    z-index: 999999 !important;
    right: 0 !important;
    bottom: 25%  !important;
    top: 25% !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* background: rgb(34,193,195); */
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    border-end-start-radius: 10px;
    border-start-start-radius: 10px;

    /* padding: 5px; */
    padding-left: 28px;
    cursor: pointer;
    height: 50%;

}

.showhide {
    position: relative;
    z-index: 9999;
    right: 1% !important;
    /* bottom: 25%  !important; */
    /* top: 50% !important; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100% !important;
    width: 150px;
}



/* position: "absolute", zIndex: 999, right: 15, bottom: "40%", cursor: "pointer"  */
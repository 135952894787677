.mb-6 {
    margin-bottom: 3em !important;
}

.fiteredSelectedRows {
    height: 36px;
}



/* const scsss = {
    position: 'sticky', // Make the column sticky
    left: 0, // Stick it to the left side
    backgroundColor: 'white', // Add a background color if needed
    zIndex: 9, // Ensure the frozen column appears above other content
    // Add additional styling as needed
  }; */
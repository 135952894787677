/* .gm-style .gm-style-iw-c {

    width: 350px !important;
    text-align: center !important;
    position: relative !important;
    box-sizing: border-box;
    bottom: 20px !important;
    transform: translate(-50%, -100%);
    font-weight: 1000 !important;
    font-size: 18px;
    border-radius: 8px;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
} */

#map{
    height: 80%;
}